function getAttrOrDefault(node, name, def) {
    var val = node.attr(name);
    return val != null ? val : def;
}

// bind a click handler to the buttons
function triggerSlideDown () {
	
	

	$('.trigger').on('click', function(e) {
		
		e.preventDefault();
		
		if ( !$(this).hasClass('active') ) {
			
			$(this).removeClass('active');
			$('div[role="region"]').attr('aria-expanded', 'false').hide();
		    
			var toggleLabel = $(this).find('span.toggleLabel');
		    var labelText = getAttrOrDefault(toggleLabel, 'data-showtext', 'Show ');
		    toggleLabel.html(labelText);
		}
		
		// reset everything else 
		//$(".trigger").not($(this)).removeClass('active');
		//$(".trigger").not($(this)).find('span.toggleLabel').html('Show ');
		
		
	    // reset everything else - updated 
		var toggleLabel = $('span.toggleLabel');
		var labelText = getAttrOrDefault(toggleLabel, 'data-showtext', 'Show ');
		
		$(".trigger").not($(this)).removeClass('active');
		$(".trigger").not($(this)).find(toggleLabel, 'data-showtext', 'Show ');
		
		
		$(this).toggleClass('active');
		
		if ( $(this).hasClass('active') & $(this).find('span.toggleLabel').length != 0 ) {

		    var toggleLabel = $(this).find('span.toggleLabel');
		    var labelText = getAttrOrDefault(toggleLabel, 'data-hidetext', 'Hide ');
		    toggleLabel.html(labelText);
			
		}
		else {
			
		    var toggleLabel = $(this).find('span.toggleLabel');
		    var labelText = getAttrOrDefault(toggleLabel, 'data-showtext', 'Show ');
		    toggleLabel.html(labelText);
			
		}
		
		var $region = $('#' + $(this).attr('aria-controls'));
		
		$region.slideToggle(200, function() {
				
			
			if ($region.attr('aria-expanded') == 'false') { 

				$region.attr('aria-expanded', 'true');
				$region.focus();		
			
			}
			else { 

				$region.attr('aria-expanded', 'false');

			}
			

		});
		
		
		if ( $(this).hasClass('mobileCloseTrigger') ) {
			$('.trigger').removeClass('active');
			$('div[role="region"]').attr('aria-expanded', 'true').hide();
		}
		
		e.stopPropagation();
		return false;
		
	});	

}

triggerSlideDown();

/* display options */
$('.fontOptions a').hover(function(){
		//console.log( "mouseEnter" );
		
		if ($(this).hasClass('contrast')) {
	    	$('.preview').addClass('contrast');
		}
		if ($(this).hasClass('normal')) {
			$('.preview').addClass('normal');
		}
		if ($(this).hasClass('big')) {
			$('.preview').addClass('big');
		}
		if ($(this).hasClass('small')) {
			$('.preview').addClass('small');
		}
		
	},
	function(){
		//console.log( "mouseLeave" );
		
		if ($('.preview').hasClass('contrastSet')) {
	   		$('.preview').removeClass('normal');
			$('.preview').removeClass('big');
			$('.preview').removeClass('small');
		}
		
		if (!$('.preview').hasClass('contrastSet')) {
	   		$('.preview').removeClass('normal');
			$('.preview').removeClass('big');
			$('.preview').removeClass('small');
			$('.preview').removeClass('contrast'); 
		}
		
	}
);

// makes the parallax elements
function parallax() {
	
  // create variables
  var $fwindow = $(window);
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  //var $contents = [];
  var $backgrounds = [];

  // for each of background parallax element
  $('.parallax').each(function() {
	  
    var $backgroundObj = $(this);

    $backgroundObj.__speed = ($backgroundObj.data('speed') || 2);
    $backgroundObj.__fgOffset = $backgroundObj.offset().top;
    $backgrounds.push($backgroundObj);
	
  });

  // update positions
  $fwindow.on('scroll resize', function() {
	  
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    $backgrounds.forEach(function($backgroundObj) {
      var yPos = ((scrollTop - $backgroundObj.__fgOffset) / $backgroundObj.__speed);

      $backgroundObj.css({
        backgroundPosition: '50%' + yPos*0.2 + 'px'
      });
	  
    });
  });

  // triggers winodw scroll for refresh
  $fwindow.trigger('scroll');
}

parallax ();

function scrollTopEnabled() {

	var distanceScrolled = 480;
	
	$(window).scroll(function() {
		
		if ( $(window).scrollTop() > distanceScrolled ) {
			$('a.back-to-top').fadeIn('slow');
		} else {
			$('a.back-to-top').fadeOut('slow');
		}
		
	});
	
	$('a.back-to-top').click(function() {
		
		$('html, body').animate({
			scrollTop: 0
		}, 900);
		return false;
		
	});

}

scrollTopEnabled();

$('a.shareSocial').click(function(e){
	
	e.preventDefault();
	var $link   = $(this);
	var href    = $link.attr('href');
	var network = $link.attr('data-network');
	
	var networks = {
		facebook : { width : 600, height : 300 },
		twitter  : { width : 600, height : 254 },
		google   : { width : 515, height : 490 },
		linkedin : { width : 600, height : 473 }
	};
	
	var popup = function(network){
		var options = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,';
		window.open(href, '', options+'height='+networks[network].height+',width='+networks[network].width);
	}
	
	popup(network);

});